<template>
    <v-container fluid>
		<v-row justify="center" class="ma-0">
			<v-col cols="12" sm="4" class="pa-0">
				<v-select class="mb-4" v-if="acces_autorise(['super Admin'])" :items="listeDepartements" label="Département" prepend-inner-icon="mdi-earth" outlined dense hide-details return-object v-model="departement_selectionne" style="max-width:200px" @change="onChangeDepartement">
					<template v-slot:selection="{ item, index }">
						<span>{{ item.id + ' - ' + item.nom }}</span>
					</template>
					<template v-slot:item="{ item, index }">
						<span>{{ item.id + ' - ' + item.nom }}</span>
					</template>
				</v-select>
				<div v-else>Département {{ departement_selectionne.nom + ' (' + departement_selectionne.id + ')'}}</div>
				<v-text-field label="Recherche" dense outlined clearable prepend-inner-icon="mdi-magnify" v-model="texte_recherche" hide-details></v-text-field>
				<v-toolbar dark flat dense color="blue-grey lighten-1" class="mt-4">
                    <v-toolbar-title>Centres</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-chip class="ma-2" color="blue-grey lighten-3">{{ liste_centres_filtree.length }}</v-chip>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" @click="openDialogCreerCentre = true"><v-icon>mdi-store-plus</v-icon></v-btn>
                        </template>
                        <span>Ajouter un centre</span>
                    </v-tooltip>
                </v-toolbar>
				<v-list v-if="liste_centres_filtree.length > 0" class="pb-0 liste_centres">
                    <template v-for="(centre, index) in liste_centres_filtree">
                        <v-list-item :key="centre.id" ripple @click="onSelectCentre(centre)" :class="estCentreSelectionne(centre)">
                            <v-list-item-content>
                                <v-list-item-title>{{ centre.nom}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider v-if="index + 1 < liste_centres_filtree.length" :key="'divider'+index"></v-divider>
                    </template>
                </v-list>
                <div v-else class="pa-3 text-center">
                    <span class="text-subtitle-1 primary--text font-weight-light">(aucun centre dans le département)</span>
                </div>

				<v-slide-y-transition mode="out-in">
                    <v-row class="ma-0" v-if="centreCourant != null" :key="centreCourant.id">
						<v-col cols="12" class="pa-0 pt-2">
							<v-row class="ma-0 mt-8" justify="center">
								<div class="primary--text text-h6">{{ centreCourant.nom }}</div>
								<v-spacer></v-spacer>
								<v-menu bottom left class="mr-2">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item @click="onClickModifCentre">
                                            <v-list-item-title>Renommer</v-list-item-title>
                                        </v-list-item>
										<v-list-item @click="onClickSupprCentre">
                                            <v-list-item-title>Supprimer le centre</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
								<v-btn icon @click="onClickSaveParamCentre" :loading="chargement_parametres">
									<v-icon>mdi-content-save</v-icon>
								</v-btn>
							</v-row>
							<div class="liste_param">
								<v-checkbox multiple class="ml-4" v-for="param in liste_parametres" :key="param" v-model="liste_parametres_centre_courant" :label="param" :value="param" hide-details></v-checkbox>
							</div>
							</v-col>
					</v-row>
				</v-slide-y-transition>
			
			</v-col>

			<v-col cols="12" sm="7" offset-sm="1" class="pa-0">
				<v-row align="center" justify="center" class="ma-0">
					<div class="text-center primary--text text-h5 font-weight-light">FMPA départementale ({{departement_selectionne.id}})</div>
				</v-row>
				<v-row align="center" class="ma-0 mb-4 px-4 mt-4 mb-8">
					<v-btn fab outlined small @click="annee_precedente" :loading="chargement_annee_precedente">
						<v-icon>mdi-chevron-left</v-icon>
					</v-btn>
					<v-slide-y-transition mode="out-in">
						<div class="flex-grow-1 text-center primary--text text-h5 font-weight-light" style="cursor: pointer" :key="annee" @click="annee_courante">{{ annee }}</div>
					</v-slide-y-transition>
					<v-btn fab outlined small @click="annee_suivante" :loading="chargement_annee_suivante">
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</v-row>
				<v-row class="ma-0 mb-6 pr-1">
					<v-spacer/>
					<v-btn depressed class="ml-1 mb-1" color="deep-purple lighten-4" small v-if="!estAnneePassee" @click="onClickAjoutTheme">Ajouter un thème</v-btn>					
				</v-row>
				
				<v-fade-transition mode="out-in">
					<v-row class="ma-0 zone_theme" v-if="liste_themes_courant.length > 0" :key="'theme_'+annee_courante_chargee+'_'+departement_selectionne.id">
						<v-col class="pa-0 pr-2">
							<v-card v-for="theme in liste_themes_courant" :key="theme.id" outlined class="mb-2 pl-2" elevation="1" color="blue-grey lighten-5">
								<v-row class="ma-0 py-1 pt-2">
									<v-col class="pa-0">
										<v-row class="ma-0" align="center">
											<v-chip x-small label text-color="white"  :color="getCouleurCategorie(theme.categorie)">{{theme.categorie}}</v-chip>
											<!-- <v-chip label x-small outlined class="font-weight-bold ml-2" :color="getCouleurCategorie(theme.categorie)" v-if="estSPPSPVonly(theme)">{{theme.pour_spp ? 'SPP' : 'SPV'}}</v-chip> -->
											<div class="ml-2 text--darken-2 grey--text font-weight-bold text-subtitle-2">{{theme.titre}}</div>
											<v-spacer></v-spacer>
											<v-chip label x-small v-if="theme.pour_spp">SPP</v-chip>
											<v-chip label x-small v-if="theme.pour_spv" class="mx-1">SPV</v-chip>
											<v-icon v-if="theme.id_type != 4" size="20" class="mr-1">mdi-clock-time-eight-outline</v-icon>
											<div v-if="theme.id_type != 4" class="text-caption">{{formatDureePrevueTheme(theme)}}</div>
											<v-menu bottom left class="mr-2">
												<template v-slot:activator="{ on }">
													<v-btn small class="ml-2" icon v-on="on">
														<v-icon>mdi-dots-vertical</v-icon>
													</v-btn>
												</template>
												<v-list dense>
													<v-list-item @click="onClickModifTheme(theme)">
														<v-list-item-title>Modifier</v-list-item-title>
													</v-list-item>
													<v-list-item @click="onClickSupprTheme(theme)" :disabled="theme.nombre_realisations > 0">
														<v-list-item-title>Supprimer</v-list-item-title>
													</v-list-item>
												</v-list>
											</v-menu>
										</v-row>
										<div class="text-caption mt-2">{{theme.description}}</div>
										<v-row class="ma-0" align="center">
											<div class="text-overline">Applicabilité CS:</div>
											<v-chip label outlined x-small color="red darken-1" class="ml-1" v-if="theme.centres.length == 0">Aucun</v-chip>
											<v-chip label outlined x-small color="green darken-1" class="ml-1" v-else-if="applicableTousCentre(theme)">TOUS</v-chip>
											<v-chip v-else v-for="centre in theme.centres" :key="centre.id" label outlined x-small color="blue darken-1" class="ml-1">{{centre.nom}}</v-chip>
										</v-row>
									</v-col>
								</v-row>
							</v-card>
						</v-col>	
					</v-row>
					<v-row v-else class="ma-0 mt-12" justify="center" :key="null+''+annee_courante_chargee+'_'+departement_selectionne.id">
						<div class="text-subtitle-1 font-italic font-weight-light text-center">(aucun thème renseigné pour cette année)</div>
					</v-row>
				</v-fade-transition>
			</v-col>
		</v-row>

		<!-- dialogue confirmation -->
        <v-dialog v-model="openDialogueConfirmation" max-width="290" persistent>
            <v-card>
                <v-toolbar dark flat dense color="primary">
                    <v-toolbar-title>
                        <v-card-title>{{ dialogue_confirmation.titre }}</v-card-title>
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-3">{{ dialogue_confirmation.texte }}</v-card-text>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="blue-grey darken-1" text @click="annuleConfirmation">Annuler</v-btn>
                    <v-btn color="blue-grey darken-1" text @click="validConfirmation" :loading="chargement_confirmation">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

		<!-- dialogue ajout/modif centre -->
        <v-dialog v-model="openDialogCreerCentre" max-width="290">
            <v-card>
                <v-toolbar dark flat dense color="primary">
                    <v-toolbar-title>
                        <v-card-title v-if="!formulaireCentre.id_centre">Ajout d'un centre</v-card-title>
						<v-card-title v-else>Modification du centre</v-card-title>
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-3">
                    <v-row class="ma-0">
                        <v-text-field v-model.trim="$v.formulaireCentre.nom.$model" label="Nom" :error-messages="erreursAjout"></v-text-field>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="blue-grey darken-1" text @click="onClickAnnuleModifCentre">Annuler</v-btn>
                    <v-btn color="blue-grey darken-1" text @click="modifCentre" :loading="chargement_modif_centre" :disabled="estFormulaireAjoutInvalid">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

		<!-- dialogue création/update thème -->
        <v-dialog v-model="openDialogueCreerTheme" persistent max-width="800px">
            <v-card>
                <v-toolbar dark flat dense color="primary">
                    <v-toolbar-title>
                        <v-card-title>{{formulaireTheme.id == null ? 'Ajouter un thème' : 'Modifier le thème ' + formulaireTheme.titre}}</v-card-title> 
                    </v-toolbar-title>
                </v-toolbar>
                
                <v-card-text class="px-4 pb-0 mb-2">
                    <v-form class="mt-3">
						<v-row class="ma-0">
							<v-select outlined dense hide-details :items="categoriesFMPA" label="Catégorie" v-model="$v.formulaireTheme.id_type.$model" :error-messages="erreursCategorie" item-value="id" item-text="sigle" style="max-width: 120px"></v-select>
							<v-text-field class="ml-2" outlined dense hide-details label="Titre" v-model.trim="$v.formulaireTheme.titre.$model" :error-messages="erreursTitre"></v-text-field>
							<v-text-field v-if="$v.formulaireTheme.id_type.$model != 4" outlined dense hide-details label="Durée" v-model.trim="$v.formulaireTheme.duree_prevue.$model" :error-messages="!($v.formulaireTheme.duree_prevue.required && $v.formulaireTheme.duree_prevue.formatHeure) ? ['Obligatoire']:[]" class="ml-1" style="max-width: 70px"></v-text-field>
						</v-row>
						<v-row class="ma-0 mt-4">
							<v-text-field outlined dense hide-details label="Description" v-model.trim="$v.formulaireTheme.description.$model" :error-messages="erreursDescription"></v-text-field>
						</v-row>
						<v-row class="ma-0 mt-2">
							<v-checkbox hide-details dense class="ma-0" v-model="formulaireTheme.pour_spp" label="SPP"></v-checkbox>
							<v-checkbox hide-details dense class="ma-0 ml-3" v-model="formulaireTheme.pour_spv" label="SPV"></v-checkbox>
						</v-row>
						<v-row class="ma-0 mt-2">
							<div class="text-overline">CS concernés:</div>
						</v-row>
						<v-row class="ma-0 mt-2">
							<v-select outlined dense hide-details :items="departement_selectionne.centres" multiple label="Centres" item-text="nom" item-value="id" return-object hide-details v-model="formulaireTheme.centres">
								<template v-slot:selection="{ item, index }">
									<v-chip v-if="index < 5" label small>{{item.nom}}</v-chip>
									<span v-if="index === 5" class="grey--text text-caption">(+{{ formulaireTheme.centres.length - 5 }} autres)</span>
								</template>
							</v-select>
						</v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue-grey darken-1" text @click="onClickAnnuleCreerTheme">Annuler</v-btn>
                    <v-btn color="blue-grey darken-1" text @click="creerModifierTheme" :loading="chargement_creer_theme" :disabled="estFormulaireCreerThemeInvalid">OK</v-btn>  
                </v-card-actions>
            </v-card> 
        </v-dialog>
	</v-container>
</template>

<script>
	import axios from 'axios'
	import { required } from 'vuelidate/lib/validators'
	import moment from 'moment'
	moment.locale('fr')
	import { groupBy, sortBy } from 'lodash'
	
    export default {
		
        created() {
			this.$store.commit('majTitrePage', 'Gestion département')
        },
        mounted() {
			//	initialisation du département
			this.$store.dispatch('getListeDepartements').then( () => {
				this.departement_selectionne = this.listeDepartements.find( departement => departement.id ==  this.$store.getters.membreCourant.centre.departement)
				this.annee = parseInt(moment().format('YYYY'))
				this.$store.dispatch('getCategoriesFMPA')
				this.fetchFMPA(this.annee)
			})
			
        },
        data() {
			return {
				annee: null,
				annee_courante_chargee: null,	//sert à charger l'annee apres le fetch pour la transition fade au bon moment
				centreCourant: null,
				chargement_annee_precedente: false,
				chargement_annee_suivante: false,
				chargement_confirmation: false,
				chargement_creer_theme: false,
				chargement_modif_centre: false,
				chargement_parametres: false,
				departement_selectionne: null,
				dialogue_confirmation: {
                    titre: '',
                    texte: '',
					action: '',
					id_element: null
				},
				formulaireCentre: {
					id_centre: null,
					nom: null
				},
				formulaireTheme: {
					id: null,
					id_type: null,
					titre: '',
					description: '',
					duree_prevue: '00:00',
					pour_spv: false,
					pour_spp: false,
					centres: []
				},
				id_fmpa_courante: null,
				liste_parametres: [	// liste de tous les paramètres possibles pour chaque centre
					'acces planning spv',
					'acces presence manoeuvres',
					'acces suivi fmpa',
					'acces dispos centre',
					'acces plannification gardes',
					'acces gestion astreintes manoeuvres',
					'acces gestion fmpa',
					'acces gestion comptes',
					'acces gestion equipes',
					'acces statistiques',
					'acces journal',
					'gestion agatt'
				],
				liste_parametres_centre_courant: [],
				liste_themes_courant: [],
				openDialogueConfirmation: false,
				openDialogCreerCentre: false,
				openDialogueCreerTheme: false,
				texte_recherche: ''  
			}
		},
		computed: {
			categoriesFMPA() {
				return this.$store.getters.categoriesFMPA
			},
			erreursAjout() {
                const errors = []
                if (!this.$v.formulaireCentre.nom.$dirty) return errors
                !this.$v.formulaireCentre.nom.required && errors.push('Le nom est obligatoire')

                if(this.departement_selectionne.centres.find(centre => centre.nom == this.formulaireCentre.nom) != undefined) {
                    errors.push('Ce nom existe déjà')
                }

                return errors
			},
			estAnneePassee() {
				//	renvoie true si l'année est terminee
				const dernier_jour = moment(this.annee, 'YYYY').endOf('year')
				return moment().isAfter(dernier_jour)
			},
			erreursCategorie() {
                const errors = []
                if (!this.$v.formulaireTheme.id_type.$dirty) return errors
                !this.$v.formulaireTheme.id_type.required && errors.push('La catégorie est obligatoire')
                return errors
			},
			erreursDescription() {
				const errors = []
                if (!this.$v.formulaireTheme.description.$dirty) return errors
                !this.$v.formulaireTheme.description.required && errors.push('La description est obligatoire')
                return errors
			},
			erreursTitre() {
                const errors = []
                if (!this.$v.formulaireTheme.titre.$dirty) return errors
                !this.$v.formulaireTheme.titre.required && errors.push('Le titre est obligatoire')
                return errors
            },
			estFormulaireAjoutInvalid() {
                const existe_deja = this.departement_selectionne?.centres.find(centre => centre.nom == this.formulaireCentre.nom)
                return this.$v.formulaireCentre.$invalid || existe_deja != undefined
			},
			estFormulaireCreerThemeInvalid() {
				return this.$v.formulaireTheme.$invalid
			},
			estMembreCourantSuperAdmin() {
                const roles_utilisateur = this.$store.getters.membreCourant.roles
                return roles_utilisateur.find(role => role.name == 'Super admin')
            },
			liste_centres_filtree() {
				if(!this.departement_selectionne) return []
				const liste_complete = this.departement_selectionne.centres
				if(this.texte_recherche){
                    return liste_complete.filter( element => {
                        return (
							element.nom.toLowerCase().indexOf(this.texte_recherche.toLowerCase()) > -1
                        )
                    })
                }
                return liste_complete
            },
			listeDepartements() {
				return this.$store.getters.liste_departements
			},
			
  
		},
		methods: {
			acces_autorise(permissions_requises, acces_admin = true) {
                // on vérifie si l'utilisateur a les droits
                //  "permissions_requises" est un tableau de permissions.
                //  return true si l'utilisateur possede l'une des permissions du tableau

                // "acces_admin"  donne ou non l'autorisation à l'admin
                
                let permission_trouvee = false

                if(permissions_requises.length > 0 && this.$store.getters.membreCourant) {
                
                    const roles_utilisateur = this.$store.getters.membreCourant.roles
                    const permissions_utilisateur = this.$store.getters.membreCourant.permissions

                    const estSuperAdmin = roles_utilisateur.find(role => role.name == 'Super admin')
                    

                    //	si super admin on autorise tout
                    if (estSuperAdmin && acces_admin) {
                        permission_trouvee = true
                    }
                    else {
                        //	on cherche si la permission demandée se trouve dans un des roles de l'utilisateur
                        roles_utilisateur.forEach(role => {
                            role.permissions.forEach(permission => {
                                    if( permissions_requises.includes(permission.name) ) {
                                        permission_trouvee = true
                                    }
                                })
                        })
                    

                        // si la permission n'a pas déjà été trouvée, on cherche si elle est dans les permissions directes
                        if (!permission_trouvee) {
                            
                            permissions_utilisateur.forEach(permission => {
                                if( permissions_requises.includes(permission.name) ) {
                                    permission_trouvee = true
                                }
                            })
                        }
                    }
                }

                //	on autorise si pas de permission ou si une permission et que la permission est trouvée
                return permissions_requises.length == 0 || (permissions_requises.length > 0 && permission_trouvee)
			},
			annee_courante() {
				this.fetchFMPA(parseInt(moment().format('YYYY')))
			},
			annee_precedente() {
				this.chargement_annee_precedente = true
				this.fetchFMPA(this.annee - 1)
			},
			annee_suivante() {
				this.chargement_annee_suivante = true
				this.fetchFMPA(this.annee + 1)
			},
			annuleConfirmation() {
                this.dialogue_confirmation.titre = ''
                this.dialogue_confirmation.texte = ''
				this.dialogue_confirmation.action = ''
				this.dialogue_confirmation.id_element = null
                this.openDialogueConfirmation = false
			},
			applicableTousCentre(theme) {
				// renvoie true si le theme concerne tous les centres enregistrés dans le département
				let tous_concernes = true
				for (const centre_dept of this.departement_selectionne.centres) {
					if(!theme.centres.find(centre => centre.id == centre_dept.id)) {
						tous_concernes = false
						break
					}
				}
				return tous_concernes
			},
			creerModifierTheme() {
				// envoi vers serveur, avec l'id de la fmpa courante pour créer la FMPA en bdd si elle n'existe pas
				//  on vérifie que le formulaire est valide
                if( !this.$v.formulaireTheme.$invalid) {
					this.chargement_creer_theme = true
					let chemin = ''
					let message = ''
					if(this.formulaireTheme.id) {
						//	si id non null c'est une modif
						chemin = 'api/fmpas/modif_theme'
						message = 'Erreur lors de la modification'
					}
					else {
						chemin = 'api/fmpas/ajout_theme'
						message = 'Erreur lors de l\'ajout'
					}
					
					axios.post(chemin, {
						id_fmpa: this.id_fmpa_courante,
						annee: this.annee,
						id_departement: this.departement_selectionne.id,
						id_membre: this.$store.getters.membreCourant.id,
						...this.formulaireTheme,
						id_centres: this.formulaireTheme.centres.map(centre => centre.id)
					})
                    .then((reponse) => {
                        this.snackbar('success',reponse.data.resultat);
						this.fetchFMPA(this.annee)
                        
		            })
                    .catch((error) => {
                        this.snackbar('error', message);
                    })
                    .then(() => {
						this.chargement_creer_theme = false
						this.onClickAnnuleCreerTheme()
                    })
                }
			
			
			},
			estCentreSelectionne(centre) {
                let est_selectionne = (this.centreCourant != null && this.centreCourant.id == centre.id) ? true : false
                return est_selectionne ? 'centreSelectionne' : ''
			},
			estSPPSPVonly(theme) {
				//	renvoie true si le theme ne s'applique qu'au SPV ou qu'au SPP
				return (theme.pour_spp && !theme.pour_spv) || (!theme.pour_spp && theme.pour_spv)
			},
			fetchFMPA(annee) {
				//	on active les 2 loading si aucun ne l'est (cas du chargement initial)
				if(!this.chargement_annee_precedente && !this.chargement_annee_suivante) {
					this.chargement_annee_suivante = true
					this.chargement_annee_precedente = true
					
                }
                axios.post('api/fmpas/fmpa_departement', { 
                        annee: annee, 
						id_membre: this.$store.getters.membreCourant.id,
						id_departement: this.departement_selectionne.id
                    })
                    .then( (reponse) => {
						this.annee_courante_chargee = reponse.data.fmpa.annee
						
						const fmpa_groupee = groupBy(reponse.data.fmpa.themes, (theme) => theme.type.sigle)
						let liste_theme = []
						for (const [key, theme] of Object.entries(fmpa_groupee)) {
							liste_theme.push({
								categorie: key,
								duree_requise: theme[0].type.duree_requise,
								liste: theme
							})
						}
						this.id_fmpa_courante = reponse.data.fmpa.id ?? null
						this.annee = annee
						
						const liste_triee = liste_theme.sort((a,b) => a.categorie.localeCompare(b.categorie))
						if(liste_triee.length == 0) {
							this.liste_themes_courant = []
						}
						else {
							let liste = []
							for (const categorie of liste_triee) {
								for (const theme of categorie.liste) {
									liste.push({
										id_theme: theme.id,
										id_type: theme.id_type,
										categorie: categorie.categorie,
										titre: theme.titre,
										description: theme.description,
										pour_spv: theme.pour_spv,
										pour_spp: theme.pour_spp,
										ouverture_menu_date: false,
										duree_prevue: theme.duree_prevue,
										centres: theme.centres,
										nombre_realisations: theme.nombre_realisations
									})
								}
							}
							this.liste_themes_courant = liste
						}
						
                    })
                    .catch( (error) => {
                        this.snackbar('error', 'Erreur lors du chargement des thèmes');
                    })
                    .then(() => {
						this.chargement_annee_suivante = false
						this.chargement_annee_precedente = false
                    })
			},
			formatDureePrevueTheme(theme) {
				const somme = moment.duration(theme.duree_prevue).asMinutes()
				
				const nbre_heures = Math.floor(somme / 60)
				const nbre_minutes = somme % 60
				return parseInt(nbre_heures)+ ':' + nbre_minutes.toString().padStart(2, '0')
			},
			getCouleurCategorie(categorie) {
				let couleur = '#9E9E9E' 	//grey
				switch (categorie) {
					case 'DIV':
						couleur = '#AFB42B'
						break;
					case 'INC':
						couleur = '#FFA000'
						break;
					case 'SR':
						couleur = '#3949AB'
						break;
					case 'SSUAP':
						couleur = '#D32F2F'
						break;
					default:

						break;
				}
				return couleur
			},
			onChangeDepartement() {
				this.centreCourant = null
			},
			onClickAjoutTheme() {
				this.openDialogueCreerTheme = true
			},
			onClickAnnuleCreerTheme() {
				this.formulaireTheme.id = null
				this.formulaireTheme.id_type = null
				this.formulaireTheme.titre = ''
				this.formulaireTheme.description = ''
				this.formulaireTheme.duree_prevue = '00:00'
				this.formulaireTheme.pour_spv = false
				this.formulaireTheme.pour_spp = false
				this.formulaireTheme.centres = []
				this.$v.formulaireTheme.$reset()
				this.openDialogueCreerTheme = false
			},
			onClickAnnuleModifCentre() {
				this.formulaireCentre.id = null
				this.formulaireCentre.nom = null
				this.openDialogCreerCentre = false
			},
			onClickModifCentre() {
				this.formulaireCentre.id_centre = this.centreCourant.id
				this.formulaireCentre.nom = this.centreCourant.nom
				this.openDialogCreerCentre = true
			},
			onClickModifTheme(theme) {
				this.formulaireTheme.id = theme.id_theme
				this.formulaireTheme.id_type = theme.id_type
				this.formulaireTheme.titre = theme.titre
				this.formulaireTheme.description = theme.description
				this.formulaireTheme.duree_prevue = moment(theme.duree_prevue, 'HH:mm:ss').format('HH:mm')
				this.formulaireTheme.pour_spv = theme.pour_spv
				this.formulaireTheme.pour_spp = theme.pour_spp
				this.formulaireTheme.centres = theme.centres
				this.openDialogueCreerTheme = true
			},
			onClickSaveParamCentre() {
				this.chargement_parametres = true
				axios.post('api/departement/parametres_centre', {
						id_centre: this.centreCourant.id,
						id_membre: this.$store.getters.membreCourant.id,
						parametres: this.liste_parametres_centre_courant
					})
                    .then((reponse) => {
                        this.snackbar('success',reponse.data.resultat);
						this.$store.dispatch('getListeDepartements').then( () => {
							this.departement_selectionne = this.listeDepartements.find( departement => departement.id ==  this.departement_selectionne.id)
							this.centreCourant = this.departement_selectionne.centres.find( centre => centre.id ==  this.centreCourant.id)
						})
                        
		            })
                    .catch((error) => {
                        this.snackbar('error', 'Erreur lors de la modification des paramètres');
                    })
                    .then(() => {
						this.chargement_parametres = false
                    })
			},
			onClickSupprCentre() {
				this.dialogue_confirmation.titre = 'Suppression'
				this.dialogue_confirmation.texte = 'Supprimer le centre '+this.centreCourant.nom +' ?'
				this.dialogue_confirmation.action = 'suppr_centre'
				this.openDialogueConfirmation = true
			},
			onClickSupprTheme(theme) {
				this.dialogue_confirmation.titre = 'Suppression'
				this.dialogue_confirmation.texte = 'Supprimer le thème '+theme.titre +' ?'
				this.dialogue_confirmation.action = 'suppr_theme'
				this.dialogue_confirmation.id_element = theme.id_theme
				this.openDialogueConfirmation = true
			},
			onSelectCentre(centre) {
				this.centreCourant = centre
				this.liste_parametres_centre_courant = this.centreCourant.parametres
			},
			modifCentre() {
				//  on vérifie que le formulaire est valide
                if( !this.$v.formulaireCentre.$invalid) {
					this.chargement_modif_centre = true
					let chemin = ''
					let message = ''
					if(this.formulaireCentre.id_centre) {
						//	si id non null c'est une modif
						chemin = 'api/departement/modif_centre'
						message = 'Erreur lors de la modification'
					}
					else {
						chemin = 'api/departement/ajout_centre'
						message = 'Erreur lors de l\'ajout'
					}
					
					axios.post(chemin, {
						id_departement: this.departement_selectionne.id,
						id_centre: this.formulaireCentre.id_centre,
						nom: this.formulaireCentre.nom,
						id_membre: this.$store.getters.membreCourant.id
					})
                    .then((reponse) => {
                        this.snackbar('success',reponse.data.resultat);
						this.$store.dispatch('getListeDepartements').then( () => {
							this.departement_selectionne = this.listeDepartements.find( departement => departement.id ==  this.departement_selectionne.id)
							this.centreCourant = this.departement_selectionne.centres.find( centre => centre.id ==  this.centreCourant.id)
						})
                        
		            })
                    .catch((error) => {
                        this.snackbar('error', message);
                    })
                    .then(() => {
						this.chargement_modif_centre = false
						this.onClickAnnuleModifCentre()
                    })
                }
			},		
			premiereLettreMaj(text){
				return text.charAt(0).toUpperCase();
			},
			snackbar(type,message){
                this.$store.commit('afficheSnackbar', {type: type, message: message});
			},
			validConfirmation() {
                switch (this.dialogue_confirmation.action) {
                    case 'suppr_centre':
                    	this.chargement_confirmation = true
                    	axios.post('api/departement/suppr_centre', { id_centre: this.centreCourant.id, id_membre: this.$store.getters.membreCourant.id})
                            .then((reponse) => {
                                this.snackbar('success',reponse.data.resultat);
                                this.$store.dispatch('getListeDepartements').then( () => {
									this.departement_selectionne = this.listeDepartements.find( departement => departement.id ==  this.departement_selectionne.id)
								})
                                this.centreCourant = null
                            })
                            .catch((error) => {
                                this.snackbar('error', error.response.data.error);
                            })
                            .then(() => {
                                this.chargement_confirmation = false
                                this.annuleConfirmation()
                    		})
                        break;
					
					case 'suppr_theme':
                    	this.chargement_confirmation = true
                    	axios.post('api/fmpas/suppr_theme', { id_theme: this.dialogue_confirmation.id_element, id_membre: this.$store.getters.membreCourant.id})
                            .then((reponse) => {
                                this.snackbar('success',reponse.data.resultat);
                                this.fetchFMPA(this.annee)
                            })
                            .catch((error) => {
                                this.snackbar('error', error.response.data.error);
                            })
                            .then(() => {
                                this.chargement_confirmation = false
                                this.annuleConfirmation()
                    		})
                        break;
                    
                   
                    default:
                        break;
                }
			},

        },
        validations: {
            formulaireCentre: {
                nom: {
                    required,                
                },
			},
			formulaireTheme: {
				id_type: {
                    required,                
                },
				titre: {
                    required,                
                },
				description: {
                    required,                
                },
				duree_prevue: {
					required, 
					formatHeure(duree_prevue) {
						return /^(0?[1-9]|[0-9][0-9]):[0-5][0-9]$/.test(duree_prevue) && (this.formulaireTheme.id_type == 4 || (this.formulaireTheme.id_type != 4 && duree_prevue != '00:00'))
					},               
                },
			},
		},
		watch: {
			departement_selectionne() {
				this.liste_themes_courant = []
				this.fetchFMPA(this.annee)
			}
		}
  	}
</script>

<style>

.centreSelectionne {
    background-color:#E8F5E9;
}

.liste_centres {
    /* max-height: calc(0.5*(100vh - 250px)); */
    min-height: 295px;
	max-height: 295px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;
    scrollbar-width: thin;
}

.liste_param {
	max-height: calc(100vh - 650px);
    /* min-height: 290px; */
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;
    scrollbar-width: thin;
}

.zone_theme {
	max-height: calc(100vh - 300px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;
    scrollbar-width: thin;
}


</style>